import { useGetContractIframUrl } from '@src/api/contract-api'
import { EContractStatus, useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { reportErrorToConsole } from '@src/services/error-logger'
import { EApplicantType, EConsignoEventStatus } from '@src/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const SignContract = () => {
  const { t } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const applicantType = params.applicantType as EApplicantType
  const [creditApp, isFetchingCreditApp, refetch] = useGetCreditApplicationById(id, false)
  const [urlsDto, isLoadingApp] = useGetContractIframUrl(id, true)

  const { innerHeight: height } = window
  const [status, setStatus] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    refetch().catch(reportErrorToConsole)
    window.addEventListener('message', (event: MessageEvent<{ action?: string; status?: EConsignoEventStatus }>) => {
      if (event.data.action === 'consigoStatus') {
        setStatus(event.data.status)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFetchingCreditApp && creditApp && creditApp.contract.status !== EContractStatus.Ready) {
      navigate(`/creditapplication/${creditApp?.id}`, { replace: true })
    }
  }, [creditApp, isFetchingCreditApp, navigate])

  if (isLoadingApp || isFetchingCreditApp) {
    return (
      <div>
        <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
      </div>
    )
  }

  if (status || [EContractStatus.UnderReview, EContractStatus.Completed].includes(creditApp!.contract.status))
    return (
      <main id="flinks-validation" className="general-message">
        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h1 style={{ marginBottom: '1rem' }}>{t('common.thankYou')}</h1>
          <h3>{t('contract.received')}</h3>
          <h3>{t('contract.review')}</h3>
          <div className="btn-group full-width-mobile">
            <button
              type="button"
              className="btn btn-blue"
              style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
              onClick={() => navigate(`/creditapplication/${creditApp?.id}`, { replace: true })}
            >
              <i className="fa-regular fa-arrow-left" />
              {t('flinks.back')}
            </button>
          </div>
        </div>
      </main>
    )

  const urlDto =
    applicantType === EApplicantType.Applicant
      ? urlsDto?.find((x) => x.isForApplicant)
      : urlsDto?.find((x) => !x.isForApplicant)

  if (urlDto)
    return (
      <main id="sign_contract" className="form-section" style={{ maxWidth: '90%' }}>
        <iframe title="Sign Contract" height={`${height - 100} px`} width="100%" loading="eager" src={urlDto.url} />
      </main>
    )

  return (
    <main id="flinks-validation" className="general-message">
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        <h1 style={{ marginBottom: '1rem' }}>{t('common.error')}</h1>
        <h3>{t('contract.errorMessage')}</h3>
        <div className="btn-group full-width-mobile">
          <button
            type="button"
            className="btn btn-blue"
            style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
            onClick={() => navigate(`/creditapplication/${creditApp?.id}`, { replace: true })}
          >
            <i className="fa-regular fa-arrow-left" />
            {t('flinks.back')}
          </button>
        </div>
      </div>
    </main>
  )
}

export default SignContract
