import { TermAmountPair } from '@src/api/compute-api'
import { CreditApplicationDocument } from '@src/api/credit-api'
import { RadioButtonListOption } from '@src/components/RadioButtonList'
import { FormatCurrency } from '@src/services/Formatter'
import { RequiredDocument, RequiredDocumentWithStatus } from '@src/types'
import { t } from 'i18next'
import { useMemo } from 'react'
import { buildRequiredDocumentsWithStatus } from './credit-application-selectors'

export function useRequiredDocumentsWithStatus(
  requiredDocuments: RequiredDocument[] | undefined | null,
  documents: CreditApplicationDocument[] | undefined | null,
): RequiredDocumentWithStatus[] {
  return useMemo(() => {
    return buildRequiredDocumentsWithStatus(requiredDocuments, documents)
  }, [requiredDocuments, documents])
}

export function useTermAmountOptions(monthlyPaymentPerMonths: TermAmountPair[], lng: string): RadioButtonListOption[] {
  return useMemo(() => {
    const ret: RadioButtonListOption[] = []
    monthlyPaymentPerMonths.forEach((term) => {
      const value = {
        id: term.term.toString(),
        monthsEstimatedAt: t('loan.monthPeriodItem', {
          loanTerm: term.term,
          loanAmount: FormatCurrency(term.amount),
        }),
        loanTermText: t('loan.loanTermMonths', {
          loanTerm: term.term,
        }),
        loanAmountText: t('loan.estimatedPayments', {
          loanAmount: FormatCurrency(term.amount),
        }),
      }
      ret.push(value)
    })
    return ret
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyPaymentPerMonths, lng])
}
