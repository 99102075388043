import { yupResolver } from '@hookform/resolvers/yup'
import { InputText } from '@src/components'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import { Constants } from '@src/types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoanAmountInput, LoanAmountInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  prequalificationData: LoanAmountInput
  onPrequalificationUpdated: (data: LoanAmountInput) => void
}

const LoanAmountForm = ({ prequalificationData, onPrequalificationUpdated }: Props) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoanAmountInput>({
    mode: 'onBlur',
    resolver: yupResolver(LoanAmountInputSchema),
    defaultValues: prequalificationData,
  })

  return (
    <section className="step-content">
      <h3 className="form-question">{t('loanAmount.desiredAmount')}</h3>
      <div className="paragraph">
        <p>
          {t('loanAmount.amountThresholds', {
            minLoanAmount: FormatCurrencyNoDecimals(Constants.MinLoanAmount),
            maxLoanAmount: FormatCurrencyNoDecimals(Constants.MaxLoanAmount),
          })}
        </p>
      </div>

      <form onSubmit={handleSubmit(onPrequalificationUpdated)} id={FORM_ID}>
        <InputText
          id="requestedLoanAmountInput"
          error={errors.requestedLoanAmount}
          label="loanAmount.amount"
          wrapStyle="number-wrap"
          {...register('requestedLoanAmount')}
        />
      </form>
    </section>
  )
}

export default LoanAmountForm
