import { SubmitCoapplicantDto, useSubmitCoapplicant } from '@src/api/credit-api'
import { AcceptTermsCheckbox, AsyncActionButton } from '@src/components'
import { reportErrorToConsole, reportErrorToServer } from '@src/services/error-logger'
import { AxiosError } from 'axios'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Prequalification } from './PrequalificationSchema'
import ReviewApplicantInfo from './ReviewApplicantInfo'

type Props = {
  gotoStep: (step: string) => void
  prequalification: Prequalification
  creditApplicationId: string
}

const ReviewCoapplicant = ({ prequalification, gotoStep, creditApplicationId }: Props) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()

  const [submitCoapplicant, isSubmitting] = useSubmitCoapplicant()

  const { applicant } = prequalification

  const buildDtoAndSubmitCoapplicant = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    try {
      if (!executeRecaptcha) {
        reportErrorToConsole('ReCAPTCHA non disponible.')
        return
      }

      const recaptchaToken = await executeRecaptcha('submit_postCreditApplicationAndPrequalify')
      const dto: SubmitCoapplicantDto = {
        recaptchaToken,
        coapplicant: prequalification.applicant,
        creditApplicationId,
      }
      await submitCoapplicant(dto)
      navigate(`/creditApplication/${creditApplicationId}`, { replace: true })
    } catch (error) {
      if ((error as AxiosError).response?.status === 424) {
        navigate('/recaptcha-validation-failed')
      } else {
        reportErrorToServer(error as Error)
      }
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('review.title')}</h3>
      <div className="paragraph">
        <p>{t('review.description')}</p>
      </div>

      <form onSubmit={buildDtoAndSubmitCoapplicant}>
        <div className="review-wrap">
          <ReviewApplicantInfo applicant={applicant} gotoStep={gotoStep} />
        </div>

        <footer className="review-footer">
          <h3>
            <Trans i18nKey="prequalification.pullCreditReport" />
          </h3>

          <AcceptTermsCheckbox
            msgOnInvalid="common.acceptConditions"
            id="acceptConditions"
            termId="prequalification.acceptConditions"
          />

          <AcceptTermsCheckbox
            msgOnInvalid="common.acceptConditions"
            id="acceptAuthorise"
            termId="common.acceptAuthorization"
          >
            <br />
            {t('confirmation.authorization')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('confirmation.authorizationEnd')}
          </AcceptTermsCheckbox>
          <AsyncActionButton
            type="submit"
            className="btn btn-blue"
            disabled={isSubmitting}
            isPending={isSubmitting}
            primary
          >
            {t('review.getQuote')}
          </AsyncActionButton>
        </footer>
      </form>
    </section>
  )
}

export default ReviewCoapplicant
