import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'available-credit'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export type IframeDto = {
  url: string
  isForApplicant: boolean
}

const getContractIframeUrl = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<IframeDto[]>(
    `/api/creditApplication/${encodeURIComponent(id)}/contract/iframeLink`,
  )

  return response.data
}

export function useGetContractIframUrl(
  creditApplicationId: string,
  enabled: boolean,
): [IframeDto[] | undefined, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getContractIframeUrl,
    enabled: !!creditApplicationId && enabled,
  })

  return [data, isFetching]
}
