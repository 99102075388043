import { useTranslation } from 'react-i18next'
import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areApplicantIncomesAreUnderReview,
  hasMissingDocuments,
  isAwaitingContractSignature,
} from '@src/data/credit-application-selectors'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import CreditStep, { CreditStepStatus } from './CreditStep'

type ContractCreditStepProps = {
  applicantType: EApplicantType
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}
const ContractCreditStep = ({
  creditApp,
  skipBankAccountRequiredDocuments,
  applicantType,
}: ContractCreditStepProps) => {
  const { t } = useTranslation()
  const isContractReady = isAwaitingContractSignature(creditApp)
  const docStepDone = !hasMissingDocuments(creditApp)
  const applicant = applicantType === EApplicantType.Applicant ? creditApp.applicant : creditApp.coapplicant!

  const applicantIncomeStepDone =
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.Applicant, skipBankAccountRequiredDocuments) ||
    creditApp.applicant.areIncomesConfirmed

  const coapplicantIncomeStepDone =
    creditApp.coapplicant === null ||
    creditApp.coapplicant.areIncomesConfirmed ||
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.CoApplicant, skipBankAccountRequiredDocuments)

  const contractIsReceived = [EContractStatus.UnderReview, EContractStatus.Completed].includes(
    creditApp.contract.status,
  )

  let content = ''
  let status = isContractReady ? CreditStepStatus.RequiresAttention : CreditStepStatus.NotReady
  if (!isContractReady && docStepDone && applicantIncomeStepDone && coapplicantIncomeStepDone) {
    status = CreditStepStatus.Await
    content = t('overview.contractWait')
  } else if (isContractReady) content = t('overview.contractReady')
  else if (contractIsReceived) content = `${t('contract.received') as string}. ${t('contract.review') as string}`

  return (
    <CreditStep
      status={status}
      linkToDetail={`contract/${applicantType}`}
      noConnector={contractIsReceived || !isContractReady}
      title={t('overview.contractStep', { name: applicantFullName(applicant) })}
      content={content}
    />
  )
}

export default ContractCreditStep
