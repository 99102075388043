import translate from '@src/services/translate'
import React from 'react'

type Props = {
  toggleText: string
  children: JSX.Element
}
const ExpandableSection = ({ toggleText, children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <button
        style={{ textDecoration: 'underline', cursor: 'pointer', backgroundColor: 'transparent' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i
          className={isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'}
          style={{ marginRight: '0.5rem' }}
        />
        {translate(toggleText)}
      </button>
      {isOpen && children}
    </>
  )
}

export default ExpandableSection
