import translate from '@src/services/translate'
import { translateErrorCode } from '@src/types'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'
import React, { LegacyRef, useMemo } from 'react'
import { FieldError } from 'react-hook-form'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  id: string
  label: string
  error?: FieldError
  errorParams?: TOptionsBase & $Dictionary
  optionsFromEnum?: Record<string, string | number>
  optionsRootLabel?: string
  hasEmptyValue?: boolean
  disableEmptyValue?: boolean
}

const SelectInput = React.forwardRef<Props, Props>(
  (
    {
      id,
      error,
      label,
      errorParams,
      optionsFromEnum,
      optionsRootLabel,
      hasEmptyValue = false,
      disableEmptyValue = false,
      ...otherProps
    }: Props,
    ref,
  ) => {
    const options = useMemo(() => {
      if (optionsFromEnum && optionsRootLabel) {
        return Object.values(optionsFromEnum).map((item) => (
          <option key={item} value={item}>
            {translate(`${optionsRootLabel}.${item}`)}
          </option>
        ))
      }
      return null
    }, [optionsFromEnum, optionsRootLabel])

    return (
      <div className={`control-group ${error && 'error'}`}>
        <label htmlFor={id}>{translate(label)}</label>
        <div className={`dropdown-wrap  ${error && 'error'}`}>
          <select id={id} {...otherProps} ref={ref as LegacyRef<HTMLSelectElement>}>
            {hasEmptyValue && (
              <option value="" disabled={disableEmptyValue}>
                {' '}
              </option>
            )}
            {options}
          </select>
          {error && <p className="error-message">{translateErrorCode(error, errorParams)}</p>}
        </div>
      </div>
    )
  },
)

export default SelectInput
