import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { scrollToTop } from '@src/services/utils'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AddressForm from './components/AddressForm'
import ContactInformationForm from './components/ContactInformationForm'
import EmploymentForm from './components/EmploymentForm'
import PersonalInformationForm from './components/PersonalInformationForm'
import { Applicant, PersonalInformationInput, Prequalification } from './components/PrequalificationSchema'
import ReviewCoapplicant from './components/ReviewCoapplicant'
import StepperFormFooter from './components/StepperFormFooter'
import StepProgressionBar from './components/StepProgressionBar'
import { buildDefaultCoapplicant, useStepListForApplicant } from './submit-app-hooks'

const AddCoapplicantPage = () => {
  const urlParams = useParams()
  const id = urlParams.id as string
  const currentStep = urlParams.currentStep as string
  const navigate = useNavigate()
  const { t } = useTranslation()

  const steps = useStepListForApplicant()
  const currentStepId = steps.indexOf(currentStep) + 1
  const cls = `form-section step-${currentStepId}-active`

  const [creditApp, isLoadingCreditApp] = useGetCreditApplicationById(id, false)

  const [prequalInfo, setPrequalInfo] = useState(buildDefaultCoapplicant())
  const [disableNext, setDisableNext] = useState(false)

  const gotoStep = useCallback((stepPath: string) => {
    scrollToTop()
    navigate(`/creditapplication/${id}/addcoapplicant/${stepPath}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nextStepAfter = useCallback(() => {
    const nextStepIdx = steps.indexOf(currentStep.toLowerCase()) + 1
    if (nextStepIdx < steps.length) {
      gotoStep(steps[nextStepIdx])
    }
  }, [currentStep, gotoStep, steps])

  const handlePrequalificationUpdated = ({ applicant, ...data }: PersonalInformationInput) => {
    const update: Prequalification = { ...prequalInfo, ...data, applicant: { ...prequalInfo.applicant, ...applicant } }
    setPrequalInfo(update)
    nextStepAfter()
  }
  const handleApplicantUpdated = (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)
    nextStepAfter()
  }

  useLayoutEffect(scrollToTop, [])

  if (isLoadingCreditApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  return (
    <main id="add-coapplicant-form" className={cls}>
      <StepProgressionBar steps={steps} currentStep={currentStep} isCoapplicant />
      {currentStep === 'personal-information' && (
        <PersonalInformationForm
          isCoapplicant
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={handlePrequalificationUpdated}
          loanPurposeId={prequalInfo.loanPurposeId}
        />
      )}

      {currentStep === 'contact' && (
        <ContactInformationForm
          onApplicantUpdated={handleApplicantUpdated}
          applicantData={prequalInfo.applicant}
          prohibedPhone={creditApp?.applicant.cellPhone}
        />
      )}

      {currentStep === 'address' && (
        <AddressForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          applicantName={prequalInfo.applicant.firstName}
          setDisableNext={setDisableNext}
        />
      )}

      {currentStep === 'employment' && (
        <EmploymentForm applicantData={prequalInfo.applicant} onApplicantUpdated={handleApplicantUpdated} />
      )}

      {currentStep === 'review' && (
        <ReviewCoapplicant gotoStep={gotoStep} prequalification={prequalInfo} creditApplicationId={id} />
      )}

      {currentStep !== 'review' && (
        <StepperFormFooter
          currentStepIndex={steps.indexOf(currentStep) + 1}
          stepLength={steps.length}
          disableBack={false}
          disableNext={disableNext}
        />
      )}
    </main>
  )
}

export default AddCoapplicantPage
